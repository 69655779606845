export default defineI18nConfig(() => {
    return {
        legacy: false,
        datetimeFormats: {
            en: {
                monthOnly: {
                    month: 'long'
                },
                dayAndMonth: {
                    day: 'numeric',
                    month: 'long'
                }
            },
            nl: {
                dayAndMonth: {
                    day: 'numeric',
                    month: 'long'
                },
                monthOnly: {
                    month: 'long'
                }
            }
        },
        globalInjection: true,
        inheritLocale: true,
        fallbackLocale: 'nl',
        missingWarn: false,
        fallbackWarn: false,
    };
});
